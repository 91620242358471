export const ENV = 'prod'
export const GENERIC_URL = {
  local: {
    api: 'https://api-staging.pgslot-api.com/',
    newapi: 'http://localhost:6699/',
  },
  dev: {
    api: 'https://api-staging.pgslot-api.com/',
    newapi: 'https://pgslot-web-api-staging.pgslot-api.com/',
    redirect: 'https://dev.pgslot.co/games/',
  },
  uat: {
    api: 'https://api-uat.pgslot-api.com/',
    newapi: 'https://pgslot-web-api-uat.pgslot-api.com/',
    redirect: 'https://uat.pgslot.co/games/',
    apkDownload: 'https://pgslot.co/app/android/PGSlot_1.0.10.apk',
    ipaDownload: 'https://ahsyjeasd.org/j0UU.app'
  },
  prod: {
    api: 'https://api-prod.pgslot-api.com/',
    newapi: 'https://pgslot-web-api-prod.pgslot-api.com/',
    redirect: 'https://pgslot.co/games/',
    apkDownload: 'https://pgslot.co/app/android/PGSlot_1.0.10.apk',
    ipaDownload: 'https://ahsyjeasd.org/j0UU.app'
  },
}

export const BASE_URL = GENERIC_URL[ENV].api
export const REDIRECT_URL = GENERIC_URL[ENV].redirect
export const BASE_NEW_URL = GENERIC_URL[ENV].newapi
export const ANDROID_DOWNLOAD = GENERIC_URL[ENV].apkDownload
export const IPA_DOWNLOAD = GENERIC_URL[ENV].ipaDownload
export const APK_DOWNLOAD = 'preload/apk'

export const M_POST = 'POST'
export const M_GET = 'GET'
export const FETCH_VERSION = 'auth/fetch/version'

export const FETCH_VERSION_NEW_LOBBY = 'config/version'
export const PG_SECRET = 'a86f63f8722128b5c5c9a05914f70138';
export const PUBLIC_PG_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCzv7aYVAm5GPRf4r58T+quJdDzgbWwjB5ou7VAzeHNvT5vtUOztJunAQbQBTDvTZOTWZYdXTF0Tu18vY3ZMCuw0zRjjOlketMbpvP57OGhAmbL3Ont2A5fzmcc7RbiRgOOJN5FnjWMitFptUjx55Lbw84/m00Z+SdoX6A2NQDmqQIDAQAB-----END PUBLIC KEY-----';

export const FETCH_BANNER = 'game/website/promotion'
