<template>
  <div class="container px-0">
    <banner></banner>
    <!-- <section v-if="!isLogin"> -->
    <section>
      <div class="d-flex game-filter-container">
        <div class="col-3 px-0" style="padding-right:1px !important">
          <div class="game-filter-btn" :class="activeMenu('all')" @click="redirectMenu('all')">
            <font-awesome-icon class="menu-icon mr-2" icon="gamepad" />
            <span>{{ $t('home.all_game') }}</span>
          </div>
        </div>
        <div class="col-3 px-0" style="padding-right:1px !important">
          <div class="game-filter-btn" :class="activeMenu('favorite')" @click="redirectMenu('favorite')">
            <font-awesome-icon class="menu-icon mr-2" icon="star" />
            <span>{{ $t('home.favorite_game') }}</span>
          </div>
        </div>
        <div class="col-3 px-0" style="padding-right:1px !important">
          <div class="game-filter-btn" :class="activeMenu('free')" @click="redirectMenu('free')">
            <font-awesome-icon class="menu-icon mr-2" icon="heart" />
            {{ $t('home.free_game') }}
          </div>
        </div>
        <div class="col-3 px-0">
          <div class="game-filter-btn" :class="activeMenu('download')" @click="redirectMenu('download')">
            <font-awesome-icon class="menu-icon mr-2" icon="download" />
            {{ $t('home.download') }}
          </div>
        </div>
      </div>
      <transition-group name="list" tag="div" class="row px-1">
        <div v-for="(item, i) in lists" :key="+i" class="col-lg-2 col-md-3 px-2 my-1 col-4" >
          <div class="card-container px-2 text-center h-100">
            <div class="game-img" @mouseover="onHoverCardGame(i)" @mouseleave="onLeaveCardGame(i)">
              <div class="" :class="i === cardGameStyle.index ? 'scale-ribbon' : 'ribbon'">
                <div class="font-weight-bold" :class="item.kind === 'new' ? 'ribbon-new' : (item.kind === 'hit' ? 'ribbon-featured' : '')">
                  {{item.kind === 'new' ? 'New!' : (item.kind === 'hit' ? 'Featured' : '')}}
                </div>
                <b-img v-if="item.kind === 'hit'" width="100%" :src="require('../assets/ribbon/ribbon_pink.png')"></b-img>
                <b-img v-if="item.kind === 'new'" width="100%" :src="require('../assets/ribbon/ribbon_red.png')"></b-img>
              </div>
              <b-img @click="playGame(item)" left :src="item.image.large" class="" fluid alt="Responsive image"/>
            </div>
            <p class="text-center pb-3">{{ item.name }}</p>
          </div>
        </div>
      </transition-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Banner from '../components/Banner'
import { EventBus } from '../services/event-bus'

export default {
  name: 'home',
  components: {
    Banner,
  },
  created() {
    this.getGames()
    if (this.$session.get('pgslot-session')) {
      if (Object.keys(this.$session.get('pgslot-session'))) {
        this.isLogin = true
      }
    }
  },
  data() {
    return {
      gameList: [],
      hitGameList: [],
      activatedMenu: '',
      isLogin: false,
      typeGame: '',
      lists: [],
      isHover: false,
      cardGameStyle: {
        index: null,
      }
    }
  },
  methods: {
    onHoverCardGame(index) {
      this.cardGameStyle.index = index
    },
    onLeaveCardGame(index) {
      this.cardGameStyle.index = null
    },
    async getGames() {
      if (this.$session.get('pgslot-session')) {
        if (Object.keys(this.$session.get('pgslot-session'))) {
          this.$axios.defaults.headers.common['Authorization'] =
            'Bearer ' + this.$session.get('pgslot-session').token
        }
      }
      this.$axios.post(this.$api.getGamesApi, {isOnWeb: true}).then(resp => {
        if (resp.data.status.code === 0) {
          this.hitGameList = resp.data.data.filter(v => v.kind === 'hit')
          if (!this.$session.get('pgslot-session')) {
            this.gameList = resp.data.data.filter(v => ['PG', 'AMBSLOT'].includes(v.partner)).sort((a,b) => a.sortWeb - b.sortWeb)
          } else {
            this.gameList = resp.data.data.filter(v => v.partner === 'PG')
          }
          this.redirectMenu('all')
        } else {
          this.$message.error(resp.data.status.message)
          // EventBus.$emit('log-out')
        }
      })
    },
    playGame(item) {
      let strWindowFeatures = 'top=0,left=0,height=768,width=1024,scrollbars=no'
      if (!this.$session.get('pgslot-session')) {
        this.$axios.post(this.$api.getDemo, {
          "gameId": item.gameId,
          "code": item.code,
          "partner": item.partner,
          "name": item.name
        }).then(resp => {
          window.open(resp.data.data, 'DemoGame', strWindowFeatures)
        })
        if (item.url) {
          window.open(item.url.trial, 'DemoGame', strWindowFeatures)
        }
      } else {
        if (item.url) {
          window.open(item.url.real, 'Game', strWindowFeatures)
        } else {
          this.$axios
            .post(this.$api.getDragoonLink, { gameId: item.code })
            .then(resp => {
              window.open(resp.data.data.url, 'DemoGame', strWindowFeatures)
            })
            .catch(err => {
              this.$message.error(err)
            })
        }
      }
    },
    activeMenu(item) {
      if (this.activatedMenu === item) {
        return 'active-menu-item'
      }
    },
    redirectMenu(item) {
      this.activatedMenu = item
      switch (item) {
        case 'download':
          this.$router.push(item)
          break
        case 'favorite':
          this.lists = this.hitGameList
          break
        default:
          this.lists = this.gameList
          break
      }
    },
  },
  mounted() {
    EventBus.$on('get-games', () => {
      this.getGames()
    })
    EventBus.$on('logged-in', () => {
      this.isLogin = true
    })
    // EventBus.$on('logged-out', () => {
    //   this.isLogin = false
    // })
  },
}
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0%;
  left: 6.5%;
}
.scale-ribbon {
  position: absolute;
  transform: scale(1.2);
  transition: 0.5s;
  left: 1%;
}
.ribbon-new {
  position: absolute;
  transform: rotate(-45deg);
  left: 25%;
  top: 45%;
}
.ribbon-featured {
  position: absolute;
  transform: rotate(-45deg);
  left: 12%;
  top: 40%;
}
/* .ribbon:hover {
  transform: scale(1.2);
  transition: 0.5s;
} */
.active-menu-item {
  color: #e6e6e6 !important;
  background: #2f2e3f !important;
}
.card-container {
  background-color: #2f2e3f;
  border-radius: 5px;
  cursor: pointer;
  color: #e6e6e6;
}
.game-filter-btn {
  background: linear-gradient(to bottom, #32a876, #43de84);
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #2bf9a0;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
}
.game-filter-btn:hover {
  background: #2f2e3f;
  color: #e6e6e6;
}
.game-img {
  cursor: pointer;
}
.game-img:hover {
  transform: scale(1.2);
  transition: 0.5s;
}
.menu-icon {
  color: #f2ca4e;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(100px);
}
</style>
