<template>
  <div class="home-container-mobile container mt-3">
    <b-carousel
      id="carousel-1"
      class="col-12 px-0 w-100 border-radius-10"
      :interval="4000"
      controls
      style="text-shadow: 1px 1px 2px #333"
    >
      <b-carousel-slide class="border-radius-10" :img-src="newBanner" />
      <b-carousel-slide class="border-radius-10" :img-src="Lucky_wheel" />
      <b-carousel-slide class="border-radius-10" :img-src="Daily_login" />
      <b-carousel-slide class="border-radius-10" :img-src="pic01" />
      <b-carousel-slide class="border-radius-10" :img-src="pic02" />
      <b-carousel-slide class="border-radius-10" :img-src="pic03" />
      <b-carousel-slide class="border-radius-10" :img-src="pic04" />
      <b-carousel-slide class="border-radius-10" :img-src="pic05" />
      <b-carousel-slide class="border-radius-10" :img-src="pic06" />
    </b-carousel>
    <div class="playgame-container d-flex flex-column align-items-center mt-2">
      <div class="d-flex justify-content-center">
        <b-img
          left
          :src="download"
          class="download-img"
          fluid
          alt="Responsive image"
        />
      </div>
      <div class="playgame-font-color mt-4">
        ดาวน์โหลด และ ติดตั้ง Pgslot APP
      </div>
      <div class="container row justify-content-center mt-2">
        <div
          class="download-button col-6 mr-2 disable-select"
          @click="downloadAndroid()"
        >
          <span><a-icon class="mr-2" type="android"></a-icon>Android</span>
        </div>
      </div>
      <div class="separate-line"></div>
      <div class="d-flex flex-wrap mt-2">
        <div class="col-md-6 col-12 px-0">
          <b-img
            left
            :src="pgBanner"
            class="secondary-banner p-1 w-100"
            fluid
            alt="Responsive image"
          />
        </div>
        <div class="col-md-6 col-12 px-0">
          <h1 class="playgame-font-color">Pgslot เล่นได้ทุกระบบ</h1>
          <h2 class="playgame-font-color">
            เล่นง่าย ที่ไหนก็ได้ ไม่ต้องโหลดแอปก็เล่นได้
          </h2>
          <p>
            เล่นได้แล้ววันนี้! ทั้งมือถือสมาร์ทโฟน และแท็บเล็ต
            รองรับทุกระบบปฏิบัติการ iOS, Android, Window, Mac
            หรือสามารถดาวน์โหลด และติดตั้ง Application ได้ง่ายๆ เพียงคลิกเดียว
          </p>
          <p>
            ผู้นำแห่งเกมสล็อตคุณภาพ ผู้ใช้มากกว่า 20,000+ ยูสเซอร์บนระบบ
            และยังมีเกมส์อีกมากมาย เช่น เกมโป๊กเกอร์ เกมบอร์ด
            เกมยิงปลาที่มีระบบเกมส์อย่างทันสมัย
            ลูกค้าสามารถเพลิดเพลินกับการเล่นเกมส์ที่เข้าใจง่าย สะดวกสบาย
            ที่สำคัญระบบปลอดภัย ไม่มีข้อบกพร่องใด ๆ ทั้งสิ้น
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAndroid } from 'mobile-device-detect'
import pic01 from '../assets/banner/big-banner/01.png'
import pic02 from '../assets/banner/big-banner/02.png'
import pic03 from '../assets/banner/big-banner/03.png'
import pic04 from '../assets/banner/big-banner/04.png'
import pic05 from '../assets/banner/big-banner/05.png'
import pic06 from '../assets/banner/big-banner/06.png'
import Daily_login from '../assets/banner/big-banner/Daily_login.png'
import Lucky_wheel from '../assets/banner/big-banner/Lucky_wheel.png'
import pgBanner from '../assets/pg-banner.png'
import download from '../assets/download.png'
import newBanner from '../assets/banner/big-banner/th.gif'
import Vue from 'vue'
import { M_POST, BASE_URL, APK_DOWNLOAD } from '../utils/api'
import { EventBus } from '../services/event-bus'
export default {
  name: 'mobile-home',
  created() {
    this.redirectToHome()
    this.checkDevice()
  },
  data() {
    return {
      pgBanner: pgBanner,
      download: download,
      pic01: pic01,
      pic02: pic02,
      pic03: pic03,
      pic04: pic04,
      pic05: pic05,
      pic06: pic06,
      Daily_login: Daily_login,
      Lucky_wheel: Lucky_wheel,
      url: '',
      newBanner,
    }
  },
  methods: {
    playgame() {
      EventBus.$emit('playgame')
    },
    redirectToHome() {
      if (window.innerWidth > 600) {
        this.$router.push('home')
      }
    },
    downloadAndroid() {
      Vue.axios({
        url: BASE_URL + APK_DOWNLOAD,
        data: { platform: 'ANDROID' },
        method: M_POST,
      }).then(({ data }) => {
        (window.location.href = data.data.url), '_blank'
      })
    },
    checkDevice() {
      if (isAndroid === true) {
        Vue.axios({
          url: BASE_URL + APK_DOWNLOAD,
          data: { platform: 'ANDROID' },
          method: M_POST,
        })
          .then(({ data }) => {
            this.url = data.data.url
          })
      } else {
        Vue.axios({
          url: BASE_URL + APK_DOWNLOAD,
          data: { platform: 'IOS' },
          method: M_POST,
        })
          .then(({ data }) => {
            this.url = data.data.url
          })
      }
    },
  },
}
</script>

<style scoped>
.playgame-container {
  background-color: #2f2e3f;
  border-radius: 5px;
  color: #e6e6e6;
  padding: 2rem 2rem;
}
.separate-line {
  color: #2bf9a0;
  width: 70%;
  border-top: #2bf9a0 1px solid;
  margin: 1rem 0 0 0;
}
.download-img {
  max-width: 100%;
  height: 150px;
}
.playgame-font-color {
  color: #2bf9a0;
}
.download-button {
  color: #2f2e3f;
  border-radius: 5px;
  padding: 0.5rem 0;
  border-color: transparent;
  background: linear-gradient(to bottom, #32a876, #43de84);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-button:hover {
  background: linear-gradient(to bottom, #43de84, #32a876);
  color: white;
}
.home-container-mobile {
  width: 100%;
}
</style>
