import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueEasySession from 'vue-easysession'
import Antd from 'ant-design-vue';
import AOS from 'aos'
import i18n from './i18n';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faGamepad, faStar, faHeart, faDownload, faHome, faCaretDown, faPlayCircle, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BASE_URL } from './utils/api'
import interceptorsSetup from '@/middleware/axios-interceptors';

interceptorsSetup();

library.add(faCoffee, faGamepad, faStar, faHeart, faDownload, faHome, faCaretDown, faPlayCircle, faSync)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'aos/dist/aos.css'
import 'ant-design-vue/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/pg-slot.css'

var options = {
  persist: false,
  keySession: 'pgslot-session',
  expireSessionCallback: () => {
    window.location.href = '/'
  }
}

Vue.use(Antd);
Vue.use(BootstrapVue)
Vue.use(VueEasySession.install, options)
Vue.use(AOS)
Vue.use(VueAxios, axios)

Vue.prototype.$axios = axios
// dev m5 d5
// prod m7 d8
// const mobileVersion = '5'
// const desktopVersion = '5'
Vue.prototype.$api = {
  lobbyApiMobile: `https://pgslot.co/games/index.html`,
  lobbyApiDesktop: `https://pgslot.co/games/index.html`,
  loginApi: `${BASE_URL}auth/login`,
  getGamesApi: `${BASE_URL}game/list`,
  getCaptcha: `${BASE_URL}captcha/generate`,
  getDragoonLink: `${BASE_URL}game/get/dragon`,
  transferIn: `${BASE_URL}transfer/in`,
  transferOut: `${BASE_URL}transfer/out`,
  changePassword: `${BASE_URL}user/password/reset`,
  getDemo: `${BASE_URL}game/launch/demo`
}
Vue.config.productionTip = false

new Vue({
  created () {
    AOS.init({
      duration: 1200,
    })
  },
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
