<template>
  <div class="mt-2">
    <div class="mobile-login">
      <!-- <span class="mobile-login-text">ลงชื่อเข้าใช้</span>
      <div class="user-input col-10 mt-4">
        <a-icon class="append-icon col-2" type="user"/>
        <input class="user-text-field col-10" v-model="username" placeholder="ชื่อผู้ใช้" type="text">
      </div>
      <div class="user-input col-10 mt-4">
        <a-icon class="append-icon col-2" type="lock"/>
        <input class="user-text-field col-10" @keyup.enter="login()" v-model="password" placeholder="รหัสผ่าน" type="password">
      </div> -->
      <!-- <div class="col-10 mt-4 d-flex">
        <div v-html="captcha"></div>
        <a @click.prevent="getCaptcha()" class="d-flex align-items-center mx-1">
          <font-awesome-icon icon="sync" size="lg" />
        </a>
        <div class="user-input">
          <input
            class="user-text-field col-12"
            v-model="captchaValue"
            placeholder="CAPTCHA"
            type="text"
            style="font-size: 12px;"
          />
        </div>
      </div> -->
      <!-- <div class="header-button col-10 mt-4" @click="login()">เข้าสู่ระบบ</div> -->
      <label class="text-newera">Experience The New Era</label>
      <label class="text-slotgames">Slot Games</label>
      <img
        class="append-newera"
        :src="require('../assets/pgslot-newera.png')"
      />
      <div class="header-button-login col-10 mt-4" @click="toLobby()">
        PLAY NOW
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { M_POST, BASE_URL, FETCH_VERSION } from '../utils/api'

export default {
  components: {
  },
  name: 'login',
  created() {
    window.addEventListener('resize', this.redirectToHome)
    this.redirectToHome()
    Vue.axios({
      url: BASE_URL + FETCH_VERSION,
      method: M_POST,
    }).then(({ data }) => {
      this.loginlobby = data.data
    })
    // this.getCaptcha()
  },
  data() {
    return {
      username: '',
      password: '',
      loginlobby: {},
      // captchaValue: '',
      // captchaToken: '',
      // captcha: '',
    }
  },
  methods: {
    toLobby() {
      if (
        /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        window.location.href = `${this.loginlobby.mobileLogin}`
      } else {
        window.location.href = `${this.loginlobby.desktopLogin}`
      }
    },
    login() {
      // if (!this.captchaValue) {
      //   this.$message.error('กรุณากรอกผลลัพธ์ของ CAPTCHA')
      //   return false
      // }
      this.$axios
        .post(this.$api.loginApi, {
          username: this.username,
          password: this.password,
          // captcha: this.captchaValue,
          // captchaToken: this.captchaToken
        })
        .then((resp) => {
          this.username = ''
          this.password = ''
          if (resp.data.status.code === 0) {
            this.$axios.defaults.headers.common['Authorization'] =
              'Bearer ' + resp.data.data.token
            this.$session.set('pgslot-session', resp.data.data)
            if (
              /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
              )
            ) {
              ;(window.location.href = `${this.$api.lobbyApiMobile}?t=${resp.data.data.token}&language=${resp.data.data.user.language}`),
                '_blank'
            } else {
              ;(window.location.href = `${this.$api.lobbyApiDesktop}?t=${resp.data.data.token}&language=${resp.data.data.user.language}`),
                '_blank'
            }
          } else {
            this.$message.error(resp.data.status.message)
          }
        })
    },
    // getCaptcha() {
    //   this.$axios.get(this.$api.getCaptcha).then(resp => {
    //     if (resp.status === 200) {
    //       this.captcha = resp.data.data.captchaImage
    //       this.captchaToken = resp.data.data.captchaToken
    //     } else {
    //       this.$message.error(resp.data.status.message)
    //     }
    //   })
    // },
    redirectToHome() {
      if (window.innerWidth > 600) {
        this.$router.push('home')
      }
    },
  },
}
</script>

<style scoped>
.mobile-login {
  display: flex;
  background-color: #161628;
  padding: 4.5rem 0 0rem 0;
  flex-direction: column;
  align-items: center;
}
.mobile-login-text {
  color: #2bf9a0;
  font-size: 7vw;
  margin-top: 0rem;
}
</style>