<template>
  <div class="termofuse-container container overflow-scroll mt-3">
    <div class="d-flex align-items-center">
      <font-awesome-icon class="menu-icon mr-2" icon="home" @click="$router.push('home')"/>
      <p class="menu-icon m-0" @click="$router.push('home')">{{ $t('pagination.home') }}</p>
      <p class="mx-2 my-0">/</p>
      <p class="m-0">{{ $t('pagination.how_to') }}</p>
    </div>
    <div class="text-paragraph mt-3">
      {{ $t('term.column_first') }}
    </div>
    <div class="text-paragraph mt-3">
      {{ $t('term.column_second') }}
    </div>
    <div class="text-paragraph mt-3">
      {{ $t('term.column_third') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'term-of-use'
}
</script>

<style scoped>
.menu-icon {
  cursor: pointer;
  color: #2bf9a0;
}
.termofuse-container {
  background-color: #2f2e3f;
  border-radius: 5px;
  color: #e6e6e6;
  padding: 2rem 2rem;
}
.text-paragraph {
  text-indent: 5%;
}
@media only screen and (max-width: 600px) {
  .termofuse-container {
    width: 95%;
  }
}
</style>