<template>
  <div class="row mt-2 px-2">
    <b-carousel
      id="carousel-1"
      class="col-8 p-1 border-radius-10"
      :interval="4000"
      controls
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide v-for="(url, i) in largeImageList"
          :key="i" class="border-radius-10" :img-src="url[$i18n.locale]"></b-carousel-slide>
      </b-carousel>
    <div class="col-4">
      <b-img left :src="normalImageList[0][$i18n.locale]" class="secondary-banner p-1 w-100" fluid alt="reset_level"/>
      <b-img left :src="normalImageList[1][$i18n.locale]" class="secondary-banner p-1 w-100" fluid alt="Responsive image"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  M_GET,
  BASE_URL,
  FETCH_BANNER,
} from '../utils/api'


export default {
  name: 'banner',
  created() {
    this.getBanner()
  },
  data() {
    return {
      sliding: null,
      largeImageList: [],
      normalImageList: [],
    }
  },
  methods: {
    async getBanner() {
      await Vue.axios({
        url: BASE_URL + FETCH_BANNER,
        method: M_GET,
      }).then(({ data: { data } }) => {
        this.largeImageList = data.largeImages;
        this.normalImageList = data.normalImages
      })
    },
  }
}
</script>

<style scoped>
.col-4, .col-8 {
  padding: 0;
}
img {
  border-radius: 10px;
}
.border-radius-10 {
  border-radius: 10px;
}
</style>
