<template>
  <div class="container mt-3 px-0">
    <div class="playgame-container d-flex flex-column align-items-center row">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 col-12">
          <b-img
            left
            :src="pgBanner"
            class="secondary-banner p-1 w-100"
            fluid
            alt="Responsive image"
          />
        </div>
        <div class="col-md-6 col-12">
          <h1 class="playgame-font-color">Pgslot เล่นได้ทุกระบบ</h1>
          <h2 class="playgame-font-color">
            เล่นง่าย ที่ไหนก็ได้ ไม่ต้องโหลดแอปก็เล่นได้
          </h2>
          <p>
            เล่นได้แล้ววันนี้! ทั้งมือถือสมาร์ทโฟน และแท็บเล็ต
            รองรับทุกระบบปฏิบัติการ iOS, Android, Window, Mac
            หรือสามารถดาวน์โหลด และติดตั้ง Application ได้ง่ายๆ เพียงคลิกเดียว
          </p>
          <p>
            ผู้นำแห่งเกมสล็อตคุณภาพ ผู้ใช้มากกว่า 20,000+ ยูสเซอร์บนระบบ
            และยังมีเกมส์อีกมากมาย เช่น เกมโป๊กเกอร์ เกมบอร์ด
            เกมยิงปลาที่มีระบบเกมส์อย่างทันสมัย
            ลูกค้าสามารถเพลิดเพลินกับการเล่นเกมส์ที่เข้าใจง่าย สะดวกสบาย
            ที่สำคัญระบบปลอดภัย ไม่มีข้อบกพร่องใด ๆ ทั้งสิ้น
          </p>
        </div>
      </div>
      <div class="separate-line my-3"></div>
      <div class="d-flex justify-content-center mt-3">
        <b-img
          left
          :src="download"
          class="download-img"
          fluid
          alt="Responsive image"
        />
      </div>
      <div class="playgame-font-color mt-4">
        ดาวน์โหลด และ ติดตั้ง Pgslot APP
      </div>
      <div class="container row justify-content-center mt-2">
        <div class="download-button px-3 disable-select" @click="downloadAndroid()">
          <span><a-icon class="mr-2" type="android"></a-icon>Android</span>
        </div>
      </div>
      <!-- <div class="container row justify-content-center mt-2">
        <div class="playgame-button col-2" @click="playgame()">เล่นทันที<a-icon class="ml-2" type="play-circle"/></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import pgBanner from '../assets/pg-banner.png'
import download from '../assets/download.png'
import { EventBus } from '../services/event-bus'
import {
  M_POST,
  BASE_URL,
  APK_DOWNLOAD,
} from '../utils/api'
export default {
  name: 'download',
  data() {
    return {
      pgBanner: pgBanner,
      download: download,
    }
  },
  methods: {
    playgame() {
      EventBus.$emit('playgame')
    },
    downloadAndroid() {
      Vue.axios({
        url: BASE_URL + APK_DOWNLOAD,
        data: { platform: 'ANDROID' },
        method: M_POST,
      }).then(({ data }) => {
        (window.location.href = data.data.url), '_blank'
      })
    }
  },
}
</script>

<style scoped>
.playgame-container {
  background-color: #2f2e3f;
  border-radius: 5px;
  color: #e6e6e6;
  padding: 2rem 2rem;
}
.separate-line {
  color: #2bf9a0;
  width: 70%;
  border-top: #2bf9a0 1px solid;
}
.download-img {
  max-width: 15%;
}
.playgame-font-color {
  color: #2bf9a0;
}
.download-button {
  color: #2f2e3f;
  border-radius: 5px;
  padding: 0.5rem 0;
  border-color: transparent;
  background: linear-gradient(to bottom, #32a876, #43de84);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-button:hover {
  background: linear-gradient(to bottom, #43de84, #32a876);
  color: white;
}
.playgame-button {
  padding: 12px 7px;
  border-radius: 5px;
  border: 2px solid #100e2e;
  background: linear-gradient(to bottom, #eadf64, #e4b330);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #100e2e;
  font-size: 1.5rem;
}
.playgame-button:hover {
  color: #e6e6e6;
  background: linear-gradient(to bottom, #e4b330, #eadf64);
}
@media only screen and (max-width: 991px) {
  .download-img {
    max-width: 25%;
  }
}
</style>
