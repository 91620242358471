<template>
  <div></div>
</template>

<script>
import Vue from 'vue'
import { isMobile } from 'mobile-device-detect'
import {
  M_GET,
  BASE_NEW_URL,
  FETCH_VERSION_NEW_LOBBY,
} from '../utils/api'

export default {
  name: 'top-header',
  created() {
    this.toLobby()
  },
  methods: {
    async toLobby() {
      await Vue.axios({
        url: BASE_NEW_URL + FETCH_VERSION_NEW_LOBBY,
        method: M_GET,
      }).then(({ data: { data } }) => {
        if (isMobile) window.location.href = `${data.version.mobileLogin}/index.html`
        else window.location.href = `${data.version.desktopLogin}/index.html`
      })
    },
  },
}
</script>
