export default {
  currencyFormatter(balance) {
    let x = balance
    if (x === 0) {
      return "0.00"
    } else if (!x) {
      return x
    }
    return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
  },
}