import Vue from 'vue'
import Router from 'vue-router'
import WebLayout from '../components/WebLayout'
import LobbyLayout from '../components/LobbyLayout'
import Home from '../components/Home'
import HomeMobile from '../components/HomeMobile'
import Login from '../components/Login'
import TermOfUse from '../components/TermOfUse' 
import Download from '../components/Download'
import Lobby from '../components/Lobby'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: { name: 'home' },
    },
    {
      path: '*',
      name: 'lobby',
      component: LobbyLayout,
      children: [
        {
          name: 'lobby',
          path: 'lobby',
          component: Lobby,
          default: true,
        }
      ]
    },
    {
      name: 'web-layout',
      path: '*',
      component: WebLayout,
      children: [
        {
          name: 'home',
          path: 'home',
          component: Home,
          default: true
        },
        {
          name: 'login',
          path: 'login',
          component: Login,
        },
        {
          name: 'termofuse',
          path: 'termofuse',
          component: TermOfUse,
        },
        {
          name: 'mHome',
          path: 'mHome',
          component: HomeMobile,
        },
        {
          name: 'download',
          path: 'download',
          component: Download,
        },
        {
          name: 'lobby',
          path: 'lobby',
          component: Lobby,
        }
      ]
    }
  ],
})

export default router
