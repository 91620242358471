<template>
  <div v-if="navbar" id="mySidenav" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" @click="$emit('close-nav')">&times;</a>
    <section class="menu-container-mobile d-flex flex-column align-items-between justify-content-between">
      <span class="separate-line"></span>
      <div class="menu-item" @click="$router.push('mHome'); $emit('close-nav')">{{ $t('menu.home') }}</div>
      <span class="separate-line"></span>
      <div class="menu-item" v-if="isAndroid" @click="$router.push('mHome'); $emit('close-nav')">{{ $t('menu.download') }}</div>
      <span class="separate-line"></span>
      <div class="menu-item">{{ $t('menu.question') }}</div>
      <span class="separate-line"></span>
      <div class="menu-item">{{ $t('menu.privacy_policy') }}</div>
      <span class="separate-line"></span>
      <div class="menu-item" @click="$router.push('termofuse'); $emit('close-nav')">{{ $t('menu.how_to_use') }}</div>
      <span class="separate-line"></span>
    </section>
  </div>
</template>
<script>

import { isAndroid } from 'mobile-device-detect'
export default {
  name: 'side-nav',

  created() {
      window.addEventListener('resize', this.redirectToHome)
      this.redirectToHome()
    },
  data() {
    return {
      navbar: true,
      isAndroid,
    }
  },
  methods: {
    redirectToHome() {
      if (window.innerWidth > 600) {
        this.navbar = false
      }
      else return this.navbar = true
    }
  }
}
</script>

<style scoped>
.menu-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem 0 20rem 0;
}
.menu-item {
  color: #2bf9a0;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
}
.separate-line {
  color: #2bf9a0;
  width: 100%;
  border-top: #2bf9a0 1px solid;
  margin: 0;
}
</style>