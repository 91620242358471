<template>
  <div class="d-flex flex-column fix-footer w-100">
    <div class="d-flex justify-content-between container">
      <div
        class="d-flex bottom-menu justify-content-between w-100 mt-2 font-size-120vw"
        :class="menuPadding"
      >
        <div v-for="(item, i) in menuList" :key="i">
          <span v-if="item !== '|'" class="menu-item" @click="redirectMenu(item)">{{ $t(item) }}</span>
          <span v-else class="seperate-bottom-menu">{{ $t(item) }}</span>
        </div>
      </div>
      <div class="os-icon pl-5">
        <a-icon class="bottom-font-color mr-2" type="android" />
        <a-icon class="bottom-font-color mr-2" type="apple" />
        <a-icon class="bottom-font-color mr-2" type="windows" />
        <a-icon class="bottom-font-color mr-2" type="html5" />
        <a-icon class="bottom-font-color" type="chrome" />
      </div>
    </div>
    <div class="d-flex container">
      <div class="single-line mt-1"></div>
    </div>
    <div class="d-flex justify-content-center container font-size-140vw mt-1">
      <p class="bottom-font-color mt-1 mb-0">POWERED BY</p>
    </div>
    <div class="container d-flex align-items-content justify-content-center flex-wrap px-0">
      <div :class="handleWidth()" class="d-block p-1" v-for="item in logoList" :key="item">
        <img class="partner-logo ml-2" :src="item" alt="Responsive image" />
        <img class="partner-logo ml-4" :src="logo_pgsoft" alt="Responsive image" />
      </div>
    </div>
    <div class="copyright-container font-size-140vw py-2 mt-2">
      <p style="margin-bottom:0px" class="copyright-text">
        {{ copyright }} © Copyright
        <span>
          <router-link :to="{ path: '/home' }" class="copyright-link">PGSLOT.CO</router-link>
        </span>
        All rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
import logo_askmebet from '../assets/logo/logo_askmebet.png'
import logo_dragoon_soft from '../assets/logo/logo_dragoon_soft.png'
import logo_pgsoft from '../assets/logo/logo_pgsoft.png'
import { EventBus } from '../services/event-bus'
import { isAndroid } from 'mobile-device-detect'
export default {
  name: 'bottom-footer',
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  data() {
    return {
      copyright: new Date().getFullYear(),
      menuList: [
        'menu.download',
        '|',
        'menu.question',
        '|',
        'menu.privacy_policy',
        '|',
        'menu.how_to_use',
        '|',
        'menu.contact',
      ],
      logo_askmebet: logo_askmebet,
      logo_dragoon_soft: logo_dragoon_soft,
      logo_pgsoft: logo_pgsoft,
      logoList: [logo_askmebet],
      width: 0,
      isAndroid,
    }
  },
  methods: {
    redirectMenu(item) {
      this.activatedMenu = item
      switch (item) {
        case 'menu.question':
          EventBus.$emit('bottom-menu-clicked', 'คำถามพบบ่อย')
          this.$router.push('login')
          break
        case 'menu.privacy_policy':
          EventBus.$emit('bottom-menu-clicked', 'ความปลอดภัย')
          this.$router.push('login')
          break
        case 'menu.how_to_use':
          EventBus.$emit('bottom-menu-clicked', 'การใช้งาน')
          this.$router.push('termofuse')
          break
        case 'menu.contact':
          EventBus.$emit('bottom-menu-clicked', 'ติดต่อเรา')
          this.$router.push('login')
          break
        case 'menu.download':
          if (window.innerWidth > 600) {
            this.$router.push('download')
          } else {
            if (isAndroid) {
              this.$router.push('mHome')
            }
          }
          break
        default:
          break
      }
    },
    handleResize() {
      this.width = window.innerWidth
    },
    handleWidth() {
      if (this.width <= 991) return 'px-1'
      return 'px-4'
    },
    fixFooter() {
      if (this.width <= 600) {
        return 'position-absolute'
      }
    },
    menuPadding() {
      if (this.width >= 600) {
        return 'px-4'
      }
    },
  },
}
</script>

<style scoped>
.menu-item {
  font-weight: bold;
  cursor: pointer;
  color: #2bf9a0;
}
.active-menu-item {
  color: #e6e6e6;
}
.fix-footer {
  bottom: 0;
  background-color: #333333;
  width: 100%;
}
.os-icon {
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.seperate-bottom-menu {
  color: #e6e6e6;
}
hr {
  color: #e6e6e6;
}
.single-line {
  border-top: 1px solid #e6e6e6;
  width: 100%;
}
.bottom-font-color {
  color: #e6e6e6;
}
.copyright-container {
  background: linear-gradient(to bottom, #43de84, #32a876);
  text-align: center;
}
.partner-logo {
  margin-top: 0.5rem;
  position: relative;
  float: left;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 991px) {
  .partner-logo {
    width: 100px !important;
    height: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .os-icon {
    display: none;
  }
  .bottom-menu {
    font-size: 2vw;
  }
  .copyright-container {
    font-size: 3vw;
  }
}
</style>
