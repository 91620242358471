<template>
  <main>
    <Snow color="#fff" zIndex="999"/>
    <div class="container">
      <top-header />
    </div>
    <router-view />
    <bottomFooter />
  </main>
</template>

<script>
import TopHeader from '../components/TopHeader'
import bottomFooter from '../components/BottomFooter'
import Snow from 'vue-niege';
export default {
  name: 'web-layout',
  components: {
    TopHeader,
    bottomFooter,
    Snow,
  },
  data() {
    return {
      path: this.$route.path
    }
  },
  created() {
    this.redirectToLogin()
  },
  methods: {
    redirectToLogin() {
      if (window.innerWidth <= 600) {
        // if (/Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //   this.$router.push('mHome')
        // } else {
        //   this.$router.push('login')
        // }
        this.$router.push('login')
      }
    },
  },
}
</script>
