<template>
  <div class="row">
    <div class="col-3 mobile-menu-icon">
      <span class="side-navbar-expand" @click="openNav()">&#9776;</span>
    </div>
    <div class="col-2-lg col-xl-3 col-md-3 col-4 logo-container">
      <b-img
        left
        :src="logoImg"
        class="logo-img"
        fluid
        alt="Responsive image"
        @click="$router.push('home')"
      />
    </div>
    <div class="col-6 logo-container-mobile">
      <b-img
        left
        :src="logoImg"
        class="logo-img"
        fluid
        alt="Responsive image"
        @click="
          $router.push('mHome')
          closeNav()
        "
      />
    </div>
    <div class="col-8 col-xl-9 col-md-9 login-section font-size-120vw">
      <div class="col-2 offset-10 px-0 mt-2">
        <div class="language-btn" @click="handleLocale()">
          <img
            class="language-flag"
            :src="`${localeList[currentLocale].pic}`"
            alt="Timor-Leste Flag"
            style="user-select: none;"
          />
          <p class="my-0" style="user-select: none;">{{ localeList[currentLocale].name }}</p>
          <font-awesome-icon class="" icon="caret-down" />
        </div>
      </div>
      <div
        v-if="!isLogin"
        class="justify-content-end d-flex login-container mt-2"
      >
        <!-- <div class="user-input col-3">
          <a-icon class="append-icon col-2" type="user" />
          <input
            class="user-text-field col-10"
            v-model="username"
            placeholder="ชื่อผู้ใช้"
            type="text"
          />
        </div>
        <div class="user-input col-3 mx-1">
          <a-icon class="append-icon col-2" type="lock" />
          <input
            class="user-text-field col-10"
            @keyup.enter="login()"
            v-model="password"
            placeholder="รหัสผ่าน"
            type="password"
          />
        </div> -->
        <!-- <div class="col-4 d-flex">
          <div v-html="captcha"></div>
          <a @click.prevent="getCaptcha()" class="d-flex align-items-center mx-1">
            <font-awesome-icon icon="sync" size="lg" />
          </a>
          <div class="user-input">
            <input
              class="user-text-field col-12"
              v-model="captchaValue"
              placeholder="CAPTCHA"
              type="text"
              style="font-size: 12px;"
            />
          </div>
        </div> -->
        <!-- <div class="header-button col-2" @click="login()">เข้าสู่ระบบ</div> -->
        <div class="header-button col-2 size-btn" @click="toLobby()">
          {{ $t('btn.login') }}
        </div>
      </div>
      <div v-else class="justify-content-end d-flex login-container mt-2">
        <div class="header-button col-2 mr-2" v-b-modal.modal-prevent-closing>
          <a-icon class="mr-2" type="key" />
          {{ $t('menu.change_password') }}
        </div>
        <div class="header-button col-2 mr-2" @click="logout()">
          <a-icon class="mr-2" type="upload" :rotate="90" />
          {{ $t('menu.logout') }}
        </div>
        <div
          v-if="Object.values(userDetail).length"
          class="profile-container user-input d-flex mr-2"
        >
          <div class="d-flex ml-2">
            <a-icon class="append-icon" type="user" />
            <div class="d-flex flex-column ml-2">
              <span class="user-detail-nickname">{{
                userDetail.profile.nickname
              }}</span>
              <span class="user-detail-username mt-1">{{
                userDetail.username
              }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center mr-2">
            <a-icon class="append-icon mx-2" type="dollar" />
            <span class="user-detail-balance">{{
              currencyFormatter(userDetail.wallet.balance)
            }}</span>
            <span class="user-detail-balance">THB</span>
            <span @click="refreshBalance()" class="refresh-icon">
              <a-icon :spin="isRefresh" class="mx-1" type="sync" />
            </span>
          </div>
        </div>
        <div class="playgame-button col-2" @click="playgame()">
          {{ $t('menu.play_now')}}<a-icon class="ml-2" type="play-circle" />
        </div>
      </div>

      <div
        class="
          col-9
          offset-3
          d-flex
          justify-content-between
          font-size-110vw
          mt-2
          px-0
        "
      >
        <div v-for="(item, i) in menuList" :key="i">
          <span
            v-if="item !== '|'"
            class="menu-item whi"
            style="white-space: nowrap;"
            @click="redirectMenu(item)"
            >{{ $t(item) }}</span
          >
          <span v-else>{{ $t(item) }}</span>
        </div>
      </div>
    </div>
    <div class="col-3 mobile-menu-login">
      <div
        class="header-button-mobile"
        @click="
          mLogin()
          closeNav()
        "
      >
        {{ $t('menu.login') }}
      </div>
    </div>
    <side-nav @close-nav="closeNav"></side-nav>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="เปลี่ยนรหัสผ่าน"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="ตกลง"
      cancel-title="ยกเลิก"
      :ok-disabled="checkFormEmpty"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-4 text-md-right text-left mt-1">
            {{ $t('menu.new_password')}} :
          </div>
          <div class="col-md-8">
            <b-form-group
              label-for="newPassword"
              :invalid-feedback="invalidNewPassword"
              :valid-feedback="validPassword"
              :state="stateNewPassword"
            >
              <b-form-input
                id="newPassword"
                v-model="changePassword.new"
                type="password"
                size="sm"
                :state="stateNewPassword"
                required
                trim
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4 text-md-right text-left mt-1">
            {{ $t('menu.comfirm_password')}} :
          </div>
          <div class="col-md-8">
            <b-form-group
              id="fieldset-1"
              label-for="confirmPassword"
              :invalid-feedback="invalidConfirmPassword"
              :valid-feedback="validPassword"
              :state="stateConfirmPassword"
            >
              <b-form-input
                id="confirmPassword"
                v-model="changePassword.confirm"
                type="password"
                size="sm"
                :state="stateConfirmPassword"
                required
                trim
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <b-alert
      v-model="showBottom"
      class="
        position-fixed
        fixed-bottom
        m-0
        rounded-0
        downloadTap
        display-mobile
        d-lg-none
      "
      style="z-index: 2000"
      variant="#213a29"
    >
      <section class="d-flex mt-2 pd-0">
        <div class="mt-2">
          <a class="iconClose" @click="showBottom = false">&times;</a>
        </div>
        <div class="col-3">
          <img class="icon" src="/image/icon180.png" alt="crime_scene_card" />
        </div>
        <div class="col-4 text-left downloadText">
          <div>
            <span> PGSLOT แอพมือถือ </span>
          </div>
          <div>
            <span> {{ $t('menu.download_now') }} </span>
          </div>
        </div>
        <div class="col-4 text-right">
          <img
            class="iconLoading"
            src="../assets/logo/icon_loading.png"
            @click="downloadApp()"
          />
        </div>
      </section>
    </b-alert>
    <b-alert
      v-if="checkIos"
      v-model="showBar"
      class="
        position-fixed
        custom-bottom
        m-2
        rounded-0
        downloadTap-ios
        display-mobile
      "
      style="z-index: 2000"
      variant="#213a29"
    >
      <section class="d-flex mt-1 pd-0">
        <div class="col-3">
          <img
            class="icon-ios"
            src="/image/icon180.png"
            alt="crime_scene_card"
          />
        </div>
        <div class="col-9 text-left downloadText">
          <div>
            <span> Add Application </span>
          </div>
          <div>
            <span>
              Tap on
              <img
                class="icon-upload-ios"
                src="../assets/logo/icon-upload.png"
              />
              and select
            </span>
            <span class="textsub-color"> Add to Home screen </span>
          </div>
          <a class="iconClose-ios" @click="showBar = false">&times;</a>
        </div>
      </section>
    </b-alert>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import logoImg from '../assets/pg-slot-logo.png'
import SideNav from './SideNav'
import utils from '../services/utils'
import { EventBus } from '../services/event-bus'
import { isAndroid } from 'mobile-device-detect'
import {
  M_POST,
  BASE_URL,
  FETCH_VERSION,
  APK_DOWNLOAD,
  IPA_DOWNLOAD,
} from '../utils/api'
import { isMobile } from 'mobile-device-detect'
import thai from '../assets/logo/PGSlot_Lobby_Icon_Flag_Thailand.png'
import usa from '../assets/logo/PGSlot_Lobby_Icon_Flag_UnitedKingdom.png'

export default {
  name: 'top-header',
  components: {
    SideNav,
  },
  created() {
    Vue.axios({
      url: BASE_URL + FETCH_VERSION,
      method: M_POST,
    }).then(({ data }) => {
      this.loginlobby = data.data
      this.isIos()
    })
    this.getUserDetail()
    this.handleParams()
    this.width = window.innerWidth
    this.isAndroid()
    this.$i18n.locale = 'th'
  },
  data() {
    return {
      checkAndroid: false,
      checkIos: false,
      showBottom: true,
      showBar: true,
      logoImg: logoImg,
      thai: thai,
      usa: usa,
      menuList: [
        'menu.question',
        '|',
        'menu.privacy_policy',
        '|',
        'menu.how_to_use',
        '|',
        'menu.contact',
      ],
      activatedMenu: '',
      username: '',
      password: '',
      // captchaValue: '',
      // captchaToken: '',
      isLogin: false,
      userDetail: {},
      isNavOpen: false,
      urlParams: new URLSearchParams(window.location.search),
      socketAuth: false,
      isRefresh: false,
      changePassword: {
        new: '',
        confirm: '',
      },
      currentLocale: 'th',
      localeList: {
        th: {
          name: 'ไทย',
          value: 'th',
          pic: thai,
        },
        en: {
          name: 'English',
          value: 'en',
          pic: usa,
        },
      },
    }
  },
  // sockets: {
  //   connect() {
  //     if (!this.socketAuth && this.$session.get('isLogin') === true) {
  //       this.emitToken()
  //     }
  //   },
  //   disconnect() {
  //     this.socketAuth = false
  //   },
  //   disconnectTrigger(res) {
  //     this.$message.error(res.status.message)
  //     this.logout()
  //   },
  //   Authenticated(res) {
  //     this.socketAuth = true
  //   },
  // },
  computed: {
    checkFormEmpty() {
      const newPass = this.changePassword.new
      const confirm = this.changePassword.confirm
      if (!newPass && !confirm) {
        return true
      } else if (confirm !== newPass) {
        return true
      } else {
        return false
      }
    },
    stateNewPassword() {
      if (!this.changePassword.new) {
        return null
      } else if (this.changePassword.new.length >= 4) {
        return true
      } else {
        return false
      }
    },
    stateConfirmPassword() {
      const newPass = this.changePassword.new
      const confirm = this.changePassword.confirm
      if (!confirm) {
        return null
      } else if (confirm !== newPass) {
        return false
      } else {
        return true
      }
    },
    invalidNewPassword() {
      if (this.changePassword.new.length > 3) {
        return ''
      } else {
        return 'รหัสผ่านขั้นต่ำ 4 ตัว'
      }
    },
    invalidConfirmPassword() {
      if (this.changePassword.confirm.length > 3) {
        return ''
      } else {
        return 'ยืนยันรหัสผ่านขั้นต่ำ 4 ตัว'
      }
    },
    validPassword() {
      return ''
    },
  },
  methods: {
    toLobby() {
      if (
        /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        window.location.href = `${this.loginlobby.mobileLogin}`
      } else {
        window.location.href = `${this.loginlobby.desktopLogin}`
      }
    },
    isAndroid() {
      if (/Android/i.test(navigator.userAgent)) {
        this.checkAndroid = true
      }
    },
    isIos() {
      if (/iPod|iPad|iPhone/i.test(navigator.userAgent)) {
        this.checkIos = true
        if ('standalone' in window.navigator && window.navigator.standalone) {
          window.location.href = `${this.loginlobby.mobileLogin}`
        }
      }
    },
    resetModal() {
      this.changePassword.new = ''
      this.changePassword.confirm = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    // getCaptcha() {
    //   this.$axios.get(this.$api.getCaptcha).then(resp => {
    //     if (resp.status === 200) {
    //       this.captcha = resp.data.data.captchaImage
    //       this.captchaToken = resp.data.data.captchaToken
    //     } else {
    //       this.$message.error(resp.data.status.message)
    //     }
    //   })
    // },
    handleLocale() {
      if (this.$i18n.locale === 'th') this.$i18n.locale = 'en'
      else this.$i18n.locale = 'th';
      this.currentLocale = this.$i18n.locale;
    },
    handleSubmit() {
      this.$axios
        .post(this.$api.changePassword, {
          username: this.userDetail.username,
          newPassword: this.changePassword.new,
          confirmPassword: this.changePassword.confirm,
        })
        .then(async (resp) => {
          const result = resp.data
          if (resp.data.status.code === 0) {
            this.$message.success(result.status.message)
          } else {
            this.$message.error(result.status.message)
          }
        })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide()
      })
    },
    emitToken() {
      if (!this.$socket.connected) {
        this.$socket.connect()
      } else {
        this.$socket.emit('authenticate', { token: this.$session.get('token') })
      }
    },
    refreshBalance() {
      this.$socket.emit('getUserBalance', {}, (user) => {
        this.userDetail.wallet.balance = user.wallet.balance
      })
      this.isRefresh = true
      setTimeout(() => {
        this.isRefresh = false
      }, 500)
    },
    currencyFormatter(num) {
      return utils.currencyFormatter(num)
    },
    redirectMenu(item) {
      this.activatedMenu = item
      switch (item) {
        case 'menu.question':
          this.$router.push('login')
          break
        case 'menu.privacy_policy':
          this.$router.push('login')
          break
        case 'menu.how_to_use':
          this.$router.push('termofuse')
          break
        case 'menu.contact':
          this.$router.push('login')
          break
        default:
          break
      }
    },
    login() {
      // if (!this.captchaValue) {
      //   this.$message.error('กรุณากรอกผลลัพธ์ของ CAPTCHA')
      //   return false
      // }
      this.$axios
        .post(this.$api.loginApi, {
          username: this.username,
          password: this.password,
          captcha: this.captchaValue,
          captchaToken: this.captchaToken,
        })
        .then(async (resp) => {
          this.username = ''
          this.password = ''
          if (resp.data.status.code === 0) {
            this.$session.set('isLogin', true)
            this.$session.set('token', resp.data.data.token)
            this.$axios.defaults.headers.common['Authorization'] =
              'Bearer ' + resp.data.data.token
            this.$session.set('pgslot-session', resp.data.data)
            this.isLogin = true
            this.userDetail = this.$session.get('pgslot-session').user
            EventBus.$emit('logged-in')
            // let strWindowFeatures = "location=yes,height=768,width=1024,scrollbars=no,status=yes"
            // let url = `${this.$api.lobbyApi}?t=${resp.data.data.token}`
            // window.open(url, "Games", strWindowFeatures)
            if (
              /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
              )
            ) {
              ;(window.location.href = `${this.$api.lobbyApiMobile}?t=${resp.data.data.token}&language=${resp.data.data.user.language}`),
                '_blank'
            } else {
              ;(window.location.href = `${this.$api.lobbyApiDesktop}?t=${resp.data.data.token}&language=${resp.data.data.user.language}`),
                '_blank'
            }
            EventBus.$emit('get-games')
          } else {
            this.$message.error(resp.data.status.message)
            // this.logout()
          }
        })
    },
    mLogin() {
      this.$router.push('login')
    },
    logout() {
      delete this.$axios.defaults.headers.common['Authorization']
      this.$session.remove('pgslot-session')
      this.$session.set('isLogin', false)
      this.$session.remove('token')
      this.isLogin = false
      EventBus.$emit('get-games')
      EventBus.$emit('logged-out')
      // this.getCaptcha()
    },
    playgame() {
      if (this.$session.get('pgslot-session')) {
        if (Object.keys(this.$session.get('pgslot-session'))) {
          if (
            /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent,
            )
          ) {
            ;(window.location.href = `${this.$api.lobbyApiMobile}?t=${
              this.$session.get('pgslot-session').token
            }`),
              '_blank'
          } else {
            ;(window.location.href = `${this.$api.lobbyApiDesktop}?t=${
              this.$session.get('pgslot-session').token
            }`),
              '_blank'
          }
        }
      }
    },
    getUserDetail() {
      if (this.$session.get('pgslot-session')) {
        if (Object.keys(this.$session.get('pgslot-session'))) {
          this.userDetail = this.$session.get('pgslot-session').user
          this.isLogin = true
        }
      } else {
        this.userDetail = {}
        this.isLogin = false
      }
    },
    openNav() {
      if (this.isNavOpen) {
        this.closeNav()
      } else {
        this.isNavOpen = true
        document.getElementById('mySidenav').style.width = '100%'
      }
    },
    closeNav() {
      this.isNavOpen = false
      document.getElementById('mySidenav').style.width = '0'
    },
    handleParams() {
      if (this.urlParams.get('logout')) {
        this.logout()
      }
    },
    downloadApp() {
      if (this.checkAndroid === true) {
        Vue.axios({
          url: BASE_URL + APK_DOWNLOAD,
          data: { platform: 'ANDROID' },
          method: M_POST,
        }).then(({ data }) => {
          ;(window.location.href = data.data.url), '_blank'
        })
      } else {
        ;(window.location.href = `${IPA_DOWNLOAD}`), '_blank'
      }
    },
  },
  mounted() {
    EventBus.$on('log-out', () => {
      this.logout()
    })
    EventBus.$on('playgame', () => {
      this.playgame()
    })
  },
}
</script>

<style scoped>
.language-flag {
  max-width: 18%;
  border-radius: 15px;
}
.language-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2f2e3f;
  border: 2px solid #19cb54;
  border-radius: 5px;
  color: #e6e6e6;
  cursor: pointer;
  line-height: 2;
}
.playgame-button {
  padding: 6px 7px;
  border-radius: 5px;
  border: 2px solid #100e2e;
  background: linear-gradient(to bottom, #eadf64, #e4b330);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #100e2e;
}
.playgame-button:hover {
  color: #e6e6e6;
  background: linear-gradient(to bottom, #e4b330, #eadf64);
}
.downloadTap {
  background-color: #1d1d2c;
  color: #ffffff;
  padding: 1px;
}
.downloadTap-ios {
  background-color: #ffffff;
  color: #2f2e3f;
  padding: 1px;
}
.downloadTap-ios::after {
  position: absolute;
  content: '';
  height: 0px;
  width: 0px;
  left: 44%;
  bottom: -20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffffff;
  z-index: 9999;
}
.icon {
  width: 100%;
  margin-bottom: 0.5rem;
}
.icon-ios {
  width: 100%;
  margin-bottom: 0.5rem;
}
.downloadText {
  font-size: 3vw;
  padding: 0%;
  margin-top: 4%;
}
.iconClose {
  font-size: 30px;
  margin-left: 1rem;
  color: #ffffff;
}
.iconClose-ios {
  position: fixed;
  transform: scale(1.9) translate(140px, -26px);
  color: #2f2e3f;
}
.iconLoading {
  width: 50%;
  margin-top: 10%;
}
.size-btn {
  height: 30px;
}

.custom-bottom {
  bottom: 17px;
}

.icon-upload-ios {
  width: 16px;
}

.textsub-color {
  color: #3498db;
}
</style>
